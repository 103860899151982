<template>
  <main>
    <CModalExtended
      :title="tituloModal"
      color="dark"
      :show.sync="showModal"
      @update:show="closeModal($event)"
      :closeOnBackdrop="false"
      size="xl"
    >
      <loading-overlay
        :active="loadingOverlay"
        :is-full-page="true"
        loader="bars"
      />
      <CustomTabs variant="tabs" :active-tab="activeTab" @update:activeTab="handleTab">
        <CustomTab :title="$t('label.generalInfo')" icon="general">
            <CRow class="mt-3">
              <CCol sm="6">
                <CRow >
                  <CCol sm="4" class="text-right pr-0">
                    <label class="mt-1"><b class="text-danger">*</b> {{$t('label.service')}}</label>
                  </CCol>
                  <CCol sm="8">
                    <v-select
                      :placeholder="$t('label.select')"
                      :options="computedServiceList"
                      class="select-services--port"
                      v-model="ServiceModel"
                      @input="selectServices"
                      :is-valid="hasError($v.ServiceId)"
                      v-model.trim="$v.ServiceModel.$model"
                      :class="computedInputSelect ? 'select-services--correct' : (verifyServiceId ? 'select-services--error' : '') "
                      :invalid-feedback="errorMessage($v.ServiceId)"
                    />
                  </CCol>
                  <div class="col-sm-4" v-if="ServiceModel == '' && verifyServiceId"></div>
                  <div class=" col-sm-8 text-invalid-feedback text-left" v-if="ServiceModel == '' && verifyServiceId">
                    {{$t('label.required')}}
                  </div>


                  <CCol sm="4" class=" text-right pr-0 mt-3">
                    <label class="mt-1"><b class="text-danger">*</b> {{$t('label.originPort')}}</label>
                  </CCol>
                  <CCol sm="8" class="mt-3">
                    <CSelect
                      class="w-100"
                      v-uppercase
                      :options="computedServiceOriginPortList"
                      :value="ServicePortId"
                      :is-valid="hasError($v.ServicePortId)"
                      v-model.trim="$v.ServicePortId.$model"
                      @change="selectServicePort($event)"
                      :invalid-feedback="errorMessage($v.ServicePortId)"
                      :disabled="ServiceModel==''"
                      :placeholder="$t('label.select')" 
                    />
                  </CCol>


                  <CCol sm="4" class=" text-right pr-0">
                    <label class="mt-1"><b class="text-danger">*</b> {{$t('label.destinationPort')}}</label>
                  </CCol>
                  <CCol sm="8">
                    <CSelect
                      class="w-100"
                      v-uppercase
                      :options="computedServiceDestinationPortList"
                      :value="OrigitPortId"
                      :is-valid="hasError($v.OrigitPortId)"
                      v-model.trim="$v.OrigitPortId.$model"
                      @change="selectServiceOriginPort($event)"
                      :invalid-feedback="errorMessage($v.OrigitPortId)"
                      :disabled="ServiceModel==''"
                      :placeholder="$t('label.select')" 
                    />
                  </CCol>
                  <CCol sm="4" class=" text-right pr-0">
                    <label class="mt-1"><b class="text-danger text-right">*</b> {{$t('label.shippingAgency')}}</label>
                  </CCol>
                  <CCol sm="8">
                    <v-select 
                      :filter="fuseSearchShipping"
                      :options="shippingOptions"
                      :placeholder="$t('label.select')"
                      v-model.trim="$v.ShippingAgencyId.$model"
                      :is-valid="hasError($v.ShippingAgencyId)"
                      :invalid-feedback="$t('label.required')"
                      :class="isShippingValid? 'select-services--correct' : (verifyShippingAgencyId ? 'select-services--error' : '') "
                      :clearable="false"
                    />
                  </CCol>
                  <div class="col-sm-4" v-if="ShippingAgencyId == '' && verifyShippingAgencyId "></div>
                  <div class=" col-sm-8 text-invalid-feedback text-left" v-if="ShippingAgencyId == '' && verifyShippingAgencyId" >
                    {{$t('label.required')}}
                  </div>
                  <CCol sm="4" class=" text-right pr-0 mt-3">
                    <label class="mt-1"><b class="text-danger">*</b> {{$t('label.vessel')}}</label>
                  </CCol>
                  <CCol sm="8" class="mt-3">
                    <v-select
                      :placeholder="$t('label.select')"
                      :options="computedServiceVesselList"
                      class="select-services--port"
                      v-model="VesselModel"
                      :is-valid="hasError($v.ServiceVesselId)"
                      v-model.trim="$v.VesselModel.$model"
                      @input="selectVessel"
                      :class="computedSelectVessel ? 'select-services--correct' : (verifyVesselModel ? 'select-services--error' : '') "
                    >
                      <template slot="option" slot-scope="option">
                        <div class="row justify-content-between">
                          <div class="col">
                            {{ option.label }}
                          </div>
                          <div class="col text-right">
                            <CImg
                              :src="option.icon"
                              width="40"
                              height="25"
                              class="ml-5"
                            />
                          </div>
                        </div>
                      </template>
                    </v-select>
                  </CCol>
                  <div class="col-sm-4" v-if="VesselModel == '' && verifyVesselModel "></div>
                  <div class=" col-sm-8 text-invalid-feedback text-left" v-if="VesselModel == '' && verifyVesselModel" >
                    {{$t('label.required')}}
                  </div>
                  <!--<CElementCover :opacity="0.8" v-show="loadingServicesVessel">
                    <label class="d-inline">{{$t('label.load')}}... </label>
                    <CSpinner size="sm" />
                  </CElementCover>-->
                  <CCol sm="4" class=" text-right pr-0 mt-3">
                    <label class="mt-1"><b class="text-danger text-right">*</b> COLOR</label>
                  </CCol>
                  <CCol sm="8" class="mt-3">
                    <CInput
                      type="color"
                      :invalid-feedback="$t('label.required')"
                      :is-valid="hasError($v.Color)"
                      v-model.trim="$v.Color.$model"
                    />
                  </CCol>
                  <CCol sm="12">
                    <CRow>
                      <CCol v-if="editModal" sm="4" class="text-right pr-0 mb-3">
                        <label class="mt-1"><b class="text-danger">*</b>{{$t('label.status')}}</label>
                      </CCol>
                      <CCol v-if="editModal" sm="8">  
                        <CSelect
                          class="w-100"
                          v-uppercase
                          :invalid-feedback="$t('label.required')"
                          :value="ItineraryStatus"
                          :is-valid="hasError($v.ItineraryStatus)"
                          v-model.trim="$v.ItineraryStatus.$model"
                          :options="computedStatusList"
                          @change="selectStatusItinerary($event)"
                        />
                      </CCol>
                    </CRow>            
                  </CCol>  
                </CRow>          
              </CCol>
              <CCol sm="6">
                <CRow> 
                  <CCol sm="12">
                    <CCard>
                      <CCardHeader>
                        <label><b class="text-danger">*</b> ETA/ETD</label>
                      </CCardHeader>
                      <CCardBody>
                        <div class="row justify-content-between">
                          <CCol sm="2" class="pr-0 text-right">
                            <label class="text-right mt-1"><b class="text-danger">*</b>{{$t('label.arrival')}}</label>
                          </CCol>
                          <CCol sm="5">
                            <vue-datepicker
                              valueType="format"
                              format="DD/MM/YYYY"
                              :open="openDate"
                              :lang="this.$i18n.locale"
                              @update:open="functionDate($event)"
                              @clickoutside="openDate = false"
                              @select="openDate = false"
                              @pick="openDate = false"
                              @close="openDate = false"
                              :clearable="false"
                              :disabled-date="fechaNoValida"
                              v-model.trim="$v.StartDate.$model"
                              :append-to-body="false"
                              @change="evento"
                              class="w-100"
                            >
                              <template #input>
                                <CInput
                                  addLabelClasses="required text-right"
                                  addInputClasses="pr-0"
                                  class="w-100 float-left"
                                  v-uppercase
                                  v-model="StartDate"
                                  placeholder="dd/mm/aaaa"
                                  :invalid-feedback="$t('label.required')"
                                  :is-valid="hasError($v.StartDate)"
                                  v-model.trim="$v.StartDate.$model"
                                  @keypress="rightDate($event)"
                                >
                                  <template #append-content>
                                    <CIcon name="cil-calendar" />
                                    <CIcon class="ml-2" name="cil-arrow-left" />
                                  </template>
                                </CInput>
                              </template>
                              <template #icon-calendar>
                                <div style="display: none"></div>
                              </template>
                            </vue-datepicker>
                          </CCol>
                          <CCol sm="5">
                            <vue-datepicker
                              valueType="format"
                              type="time"
                              :format="'HH:mm'"
                              v-model.trim="$v.ArrivalTime.$model"
                              :clearable="false"
                              :append-to-body="false"
                              class="w-100"
                            >
                              <template #input>
                                <CInput
                                  addLabelClasses="required text-right"
                                  class="w-100 float-left"
                                  v-uppercase
                                  placeholder="HH:MM"
                                  :invalid-feedback="$t('label.required')"
                                  :is-valid="hasError($v.ArrivalTime)"
                                  v-model.trim="$v.ArrivalTime.$model"
                                  @keypress="formatHourMMHH($event)"
                                >
                                  <template #append-content>
                                    <CIcon name="cil-clock" />
                                    <CIcon class="ml-2" name="cil-arrow-left" />
                                  </template>
                                </CInput>
                              </template>
                              <template #icon-calendar>
                                <div style="display: none"></div>
                              </template>
                            </vue-datepicker>
                          </CCol>
                          <CCol sm="2" class="pr-0 text-right">
                            <label class="text-right mt-1"><b class="text-danger">*</b>{{$t('label.departure')}}</label>
                          </CCol>
                          <CCol sm="5">
                            <vue-datepicker
                              valueType="format"
                              format="DD/MM/YYYY"
                              :open="openDate2"
                              :lang="this.$i18n.locale"
                              @update:open="functionDate2($event)"
                              @clickoutside="openDate2 = false"
                              class="w-100 vue-datepicker-drive:focus"
                              @select="openDate2 = false"
                              @pick="openDate2 = false"
                              @close="openDate2 = false"
                              :clearable="false"
                              :disabled-date="fechaNoValida"
                              v-model.trim="$v.EndDate.$model"
                              :append-to-body="false"
                            >
                              <template #input>
                                <CInput
                                  addLabelClasses="required text-right"
                                  addInputClasses="pr-0"
                                  class=" w-100"
                                  v-uppercase
                                  placeholder="dd/mm/aaaa"
                                  :invalid-feedback="$t('label.required')"
                                  :is-valid="hasError($v.EndDate)"
                                  v-model.trim="$v.EndDate.$model"
                                  @keypress="rightDate($event)"
                                >
                                  <template #append-content>
                                    <CIcon name="cil-calendar" />
                                    <CIcon class="ml-2" name="cil-arrow-right" />
                                  </template>
                                </CInput>
                              </template>
                              <template #icon-calendar>
                                <div style="display: none"></div>
                              </template>
                            </vue-datepicker>
                          </CCol>
                          <CCol sm="5">
                            <vue-datepicker
                              valueType="format"
                              type="time"
                              :format="'HH:mm'"
                              v-model="TimeSail"
                              :clearable="false"
                              v-model.trim="$v.TimeSail.$model"
                              :append-to-body="false"
                              class="w-100"
                            >
                              <template #input>
                                <CInput
                                  addLabelClasses="required text-right"
                                  class="w-100 float-left"
                                  v-uppercase
                                  v-model="TimeSail"
                                  placeholder="HH:MM"
                                  :invalid-feedback="$t('label.required')"
                                  :is-valid="hasError($v.TimeSail)"
                                  v-model.trim="$v.TimeSail.$model"
                                  @keypress="formatHourMMHH($event)"
                                >
                                  <template #append-content>
                                    <CIcon name="cil-clock" />
                                    <CIcon class="ml-2" name="cil-arrow-right" />
                                  </template>
                                </CInput>
                              </template>
                              <template #icon-calendar>
                                <div style="display: none"></div>
                              </template>
                            </vue-datepicker>
                          </CCol>
                        </div>
                      </CCardBody>
                    </CCard>
                  </CCol>
                  <CCol sm="3" class="text-right pr-0 mb-3 text-area-descripcion-itinerario">
                    <label class="mt-1"><b class="text-danger">*</b> {{$t('label.voyage')}}</label>
                  </CCol>
                  <CCol sm="9">  
                    <CInput
                      class="w-100"
                      v-uppercase
                      :is-valid="hasError($v.Voyage)"
                      v-model.trim="$v.Voyage.$model"
                      :invalid-feedback="$t('label.required')"
                    />
                  </CCol>
                  <CCol sm="3" class="text-right pr-0 mb-3 text-area-descripcion-itinerario">
                    <label class="mt-1">{{$t('label.observation')}}</label>
                  </CCol>
                  <CCol sm="9" class="text-area-descripcion-itinerario">
                    <CTextarea
                      class="w-100"
                      v-uppercase
                      :invalid-feedback="$t('label.required')"
                      :is-valid="hasError($v.ItineraryObservation)"
                      v-model.trim="$v.ItineraryObservation.$model"
                    />
                  </CCol>
                </CRow> 
              </CCol>
            </CRow>
        </CustomTab>
        <CustomTab :disabled="!editModal" :title="$t('label.recordInfo')"  icon="cil-list">
          <CRow>
            <CCol sm="12">
              <dataTableExtended
                class="align-center-row-datatable"
                :fields="fields"
                :items="items"
                :items-per-page="5"
                column-filter
                pagination
                :table-filter="tableText.tableFilterText"
                :items-per-page-select="tableText.itemsPerPageText"
                :noItemsView="tableText.noItemsViewText"
                :loading="loadingTable"
              >
                <template #loading>
                  <loading/>
                </template>
              </dataTableExtended>
            </CCol>
          </CRow>
          <!--<CRow>
              <CCol sm="12"><hr /></CCol>
              <CCol sm="12" class="text-right">
                <CButton color="wipe" class="ml-1" @click="closeModal(false)">
                  <CIcon name="x" />&nbsp; {{$t('button.cancel')}}
                </CButton>
              </CCol>
            </CRow>-->
        </CustomTab>
      </CustomTabs>

      <template #footer>
        <CButton
          v-if="!editModal"
          outline
          color="add"
          @click="saveSNewItinerary"
        >
          <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
        </CButton>
        <CButton
          v-if="editModal"
          outline
          color="add"
          @click="submitUpdateItinerary"
        >
          <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
        </CButton>
        <CButton color="wipe" class="ml-1" @click="closeModal(false)">
          <CIcon name="x" />&nbsp; {{$t('button.cancel')}}
        </CButton>

      </template>
    </CModalExtended>
  </main>
</template>
<script>
import UpperCase from '@/_validations/uppercase-directive';
import GeneralMixin from '@/_mixins/general';
import { DateFormater } from '@/_helpers/funciones';
import Itinerario from '@/_validations/servicio/itinerario';
import Servicio from '@/_mixins/servicio';
import { mapState } from 'vuex';
import ChoferVehiculo from '@/_mixins/chofer-vehiculos';
import Fuse from "fuse.js";
import CustomTabs from '@/components/tabs/CustomTabs';
import CustomTab from '@/components/tabs/CustomTab';

function data() {
  return {
    showModal: false,
    loadingOverlay: false,
    loadingTable: false,
    optionsStatus: [
      { label: this.$t('label.ACTIVO'), value: 1 },
      { label: this.$t('label.INACTIVO'), value: 0 },
    ],
    Status: 1,
    openDate: false,
    DriverId: '',
    itemsLinea: [],
    items: [],
    serviceList: [],
    loadingServicesPort: false,
    selectAll: false,
    openDate2: false,
    ArrivalTime: '',
    TimeSail: '',
    StartDate: '',
    EndDate: '',
    ServiceId: '',
    ServiceModel: '',
    itemsPortList: [],
    itemsOriginPortList: [],
    ServicePortId: '',
    itemsServiceVesselList: [],
    ServiceVesselId: '',
    VesselModel: '',
    loadingServicesVessel: false,
    Voyage: '',
    ItineraryObservation: '',
    Color: '',
    OrigitPortId: '',
    ItineraryId: '',
    statusToVerify: '',
    statusSelectOptions: [],
    ItineraryStatus: 'N/A',
    ShippingAgencyId: '',
    tituloModal: '',
    shippings: [],
    fechaAnterior: new Date(),
    fechaSiguiente: new Date(),
    activeTab: 0,
    verifyServiceId: false,
    verifyVesselModel: false,
    verifyShippingAgencyId: false,
  };
}

function fields(){
  return [
    { key: 'Nro', label: '#', filter: false, _style:'width:3%;text-align:center', _classes:'text-center' },
    { key: 'Arrival', label:this.$t('label.arrival'), _style: 'width:40%;text-align:left;' },
    { key: 'Departure', label:this.$t('label.departure'), _style: 'width:30%;text-align:left;' },
    { key: 'Usuario', label:this.$t('label.user'), _style: 'width:10%;text-align:left;' },
    { key: 'Fecha', label:this.$t('label.date'), _style: 'width:10%;text-align:center;' , _classes:'text-center'}, 
  ];
}

function closeModal(event) {
  this.showModal = false;
  this.openDate = false;
  this.resetData();
  this.$emit('updated-modal', event);
}

function functionDate(event) {
  if (event) {
    this.openDate = event;
  }
}

function fuseSearchShipping(options, search) {
  const fuse = new Fuse(options, {
    keys: ["ClientName"],
    shouldSort: true
  });
  return search.length
    ? fuse.search(search).map(({ item }) => item)
    : fuse.list;
}

function functionDate2(event) {
  if (event) {
    this.openDate2 = event;
  }
}

//### Funcion mounted para cargar los datos de la tabla ###
async function mountedServiceList() {
  //this.loadingOverlay = true;
 // this.loadingOverlay = true;

  await this.$http
    .get('Service-list?CompanyBranchId='+this.user.BranchJson[0].CompanyBranchId+'&Filter=ACTIVO')
    .then((response) => {
      this.serviceList = response.data.data;
    })
    .finally(() => {
   //   this.loadingOverlay = false;
    //  this.loadingTable = false;
    })
    .catch((e) => {
    //  this.loadingOverlay = false;
    //  this.loadingTable = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    });
}

//########
// Data para las opciones del select de servicios
//########
function computedServiceList() {
  if (this.serviceList.length > 0) {
    return this.serviceList.map((item) => {
      return {
        label: item.ServiceCode,
        value: item.ServiceId,
        AgencyClientTpId: item.AgencyClientTpId,
        AgencyClientName: item.AgencyClientName,
        TpVesselId: item.TpVesselId,
      };
    });
  }
}

function computedInputSelect() {
  if (this.ServiceId === '') {
    return false;
  } else {
    return true;
  }
}

function computedSelectVessel() {
  if (this.ServiceVesselId === '') {
    return false;
  } else {
    return true;
  }
}

//### Funcion mounted para cargar los datos de la tabla ###
async function moutedServicePortList(id) {
  this.loadingServicesPort = true;
  this.loadingOverlay = true;

  await this.$http
    .get(`ServicePort-list?ServiceId=${id}`)
    .then((response) => {
      let listado = response.data.data;
      this.itemsPortList = listado;
      this.itemsOriginPortList = listado;
    })
    .finally(() => {
      this.loadingServicesPort = false;
      this.loadingOverlay = false;
    })
    .catch((e) => {
      this.loadingServicesPort = false;
      this.loadingOverlay = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    });
}

//########
// Data para las opciones del select de servicios
//########
function computedServiceDestinationPortList() {
  if (this.itemsPortList.length > 0) {
    return this.itemsPortList.map((item) => {
      return {
        label: item.PortName,
        value: item.ServicePortId,
      };
    });
  }
}

function computedServiceOriginPortList() {
  if (this.itemsOriginPortList.length > 0) {
    return this.itemsOriginPortList.map((item) => {
      return {
        label: item.PortName,
        value: item.ServicePortId,
      };
    });
  }
}
function computedMessagePortListEmpty() {
  if (this.itemsPortList.length === 0) {
    return true;
  } else {
    return false;
  }
}

async function selectServices(payload) {
  if (payload !== null) {
    this.loadingOverlay = true;
    this.ServiceId = payload.value;
    this.ServiceModel = payload.label;

    if( payload.AgencyClientName !== null && 
        payload.AgencyClientTpId !== null && 
        payload.TpVesselId !== '2A719797-70DB-437B-B55D-58F4177CB87B'){

          this.ShippingAgencyId = {
            label: payload.AgencyClientName ? payload.AgencyClientName : '',
            value: payload.AgencyClientTpId ? payload.AgencyClientTpId : '',
          }

    }else{              
      this.ShippingAgencyId = '';
    }
        
    await this.moutedServicePortList(this.ServiceId);
    await this.moutedServiceVesselList(this.ServiceId);
    await this.serviceById(this.ServiceId);
    this.loadingOverlay = false;
  } else {
    this.ServiceId = '';
    this.ServiceModel = '';
    this.itemsPortList = [];
  }
}

function selectServicePort(event) {
  this.ServicePortId = event.target.value;
}

function selectServiceOriginPort(event) {
  this.OrigitPortId = event.target.value;
}

async function selectVessel(payload) {
  if (payload !== null) {
    this.VesselModel = payload.label;
    this.ServiceVesselId = payload.value;
  } else {
    this.VesselModel = '';
    this.ServiceVesselId = '';
  }
}

//### Funcion mounted para cargar los datos de la tabla ###
async function moutedServiceVesselList(id) {
  this.loadingOverlay = true;
  this.loadingServicesVessel = true;

  await this.$http
    .get(`ServiceVessel-list?ServiceId=${id}`)
    .then((response) => {
      this.itemsServiceVesselList = response.data.data.filter(ob => ob.Status == 'ACTIVO');
    })
    .finally(() => {
      this.loadingOverlay = true;
      this.loadingServicesVessel = false;
    })
    .catch((e) => {
      this.loadingOverlay = true;
      this.loadingServicesVessel = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    });
}

//########
// Data para las opciones del select de servicios
//########
function computedServiceVesselList() {
  if (this.itemsServiceVesselList.length > 0) {
    return this.itemsServiceVesselList.map((item) => {
      return {
        label: item.VesselName,
        value: item.ServiceVesselId,
        icon:
          item.VesselFlagRoute !== ''
            ? `${this.$store.getters["connection/getBase"]}${item.VesselFlagRoute}`
            : `${this.$store.getters["connection/getBase"]}${item.VesselFlagRoute}`,
        TpVesselId: item.TpVesselId,
      };
    });
  }
}

function computedMessageVesselEmpty() {
  if (this.itemsServiceVesselList.length === 0) {
    return true;
  } else {
    return false;
  }
}

function shippingOptions() {
  return this.shippings.map((item) => Object.assign({}, item, {
    label: item.ClientName,
    value: item.ClientTpId
  }));
}

function getShipping(tipo) {
  this.$http.get("Client-list-by-activity", { TpClientId: tipo })
  .then((response) => {
    this.shippings = response.data.data;
  }).catch((err) => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  });
}

async function saveSNewItinerary() {
  try {
    this.loadingOverlay = true;
    this.$v.$touch();
    
    if (this.$v.$error) {
      this.loadingOverlay = false;
      this.verifyServiceId = true;
      this.verifyVesselModel = true;
      this.verifyShippingAgencyId = true;
      throw this.$t('label.errorsPleaseCheck');
    }

    if(this.ServicePortId === this.OrigitPortId){
      this.loadingOverlay = false;
      throw this.$t('label.notsameAsOrigin');
    }

    let portOrigin = this.itemsOriginPortList.find((item) => item.ServicePortId == this.OrigitPortId );
    let portDestiny = this.itemsPortList.find((item) => item.ServicePortId == this.ServicePortId );

    if(portOrigin.PortId !== this.branch.PortId && portDestiny.PortId !== this.branch.PortId){
      this.loadingOverlay = false;
      throw this.$t('label.onePortCompanyBranch');
    }
/*
    if (this.branch.PortId ) {
      this.loadingOverlay = false;
      this.verifyServiceId = true;
      this.verifyVesselModel = true;
      this.verifyShippingAgencyId = true;
      throw this.$t('label.errorsPleaseCheck');
    }*/

    let fecha1 = this.StartDate.split("/");
    fecha1 = fecha1[2]+fecha1[1]+fecha1[0];
    let fecha2 = this.EndDate.split("/");
    fecha2 = fecha2[2]+fecha2[1]+fecha2[0];

    if (fecha2 < fecha1) {
      this.loadingOverlay = false;
      this.EndDate = '';
      throw this.$t('label.errosDatePleaseCheck');
    } else if (fecha2==fecha1) {
      let hora2 = this.TimeSail.split(":");
      hora2 = hora2[0]+hora2[1];
      let hora1 = this.ArrivalTime.split(":");
      hora1 = hora1[0]+hora1[1];

      if (hora2 <= hora1) {
        this.loadingOverlay = false;
        this.TimeSail = '';
        throw this.$t('label.errosHourPleaseCheck');
      }
    }  

    const ServiceItineraryJson = {
      ServiceVesselId: this.ServiceVesselId,
      ServicePortOriginId: this.ServicePortId,
      ServicePortDestinationId: this.OrigitPortId,
      Eta: `${this.StartDate} ${this.ArrivalTime}`,
      Etd: `${this.EndDate} ${this.TimeSail}`,
      Voyage: this.Voyage,
      Color: this.Color,
      ShippingAgencyId: this.ShippingAgencyId.value,
      ItineraryObservation: this.ItineraryObservation,
    };

    await this.$http
      .post('ServiceItinerary-insert', ServiceItineraryJson, {
        root: 'ServiceItineraryJson',
      })
      .then((response) => {
        if (response.status === (200 || 201)) {
          const messageSuccess = response.data.data[0].Response;
          this.$emit('refresh-data-calendar');
          this.loadingOverlay = false;
          this.closeModal(false);
          this.resetData();
          this.$notify({
            group: 'container',
            title: '¡Solicitud Exitosa!',
            text: messageSuccess,
            type: 'success',
          });
        }
      })
      .catch((e) => {
        this.loadingOverlay = false;
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: e,
          type: 'error',
        });
      });
  } catch (e) {
    this.loadingOverlay = false;
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: e,
      type: 'error',
    });
  }
}

function resetData() {
  this.ServiceVesselId = '';
  this.ServicePortId = '';
  this.OrigitPortId = '';
  this.StartDate = '';
  this.ArrivalTime = '';
  this.EndDate = '';
  this.TimeSail = '';
  this.Voyage = '';
  this.Color = '';
  this.ItineraryObservation = '';
  this.ServiceModel = '';
  this.itemsPortList = [];
  this.itemsOriginPortList = [];
  this.itemsServiceVesselList = [];
  this.VesselModel = '';
  this.OrigitPortId = '';
  this.ItineraryId = '';
  this.ItineraryStatus = 'N/A';
  this.ShippingAgencyId = '';
  this.items = [];
  this.activeTab = 0;
  this.ServiceId = '';
  this.$nextTick(() => { this.$v.$reset() })
}

function handleTab(tab) {
    this.activeTab = tab;
}

async function serviceById(id) {
  //### Funcion mounted para chis.loadingOverlay = true;
  this.loadingOverlay = true;

  await this.$http
    .get(`Service-by-id?ServiceId=${id}`)
    .then((response) => {
      let listado = response.data.data[0];
      this.ServiceModel = listado.ServiceCode;
 
      let fecha1 = DateFormater.formatDateWithoutSlash(listado.StartDate);
      this.StartDate = DateFormater.formatOnlyDateWithSlash(listado.StartDate);
      this.EndDate = DateFormater.formatOnlyDateWithSlash(listado.StartDate);
      let fecha2 = DateFormater.formatDateWithoutSlash(listado.EndDate);
      this.fechaAnterior  = new Date(fecha1);
      let timezone_offset_minutes = new Date(fecha2).getTimezoneOffset();
      timezone_offset_minutes = timezone_offset_minutes == 0 ? 0 : -timezone_offset_minutes;
      let horas = timezone_offset_minutes/60;
      let nuevo = new Date(fecha2);

      if (horas < 0) nuevo.setHours(nuevo.getHours() + (horas*-1));
      this.fechaSiguiente = nuevo;

      
    })
    .finally(() => {

      this.loadingOverlay = false;
     // this.$v.$touch();
    })
    .catch((e) => {
      this.loadingOverlay = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    });
}

async function mounteStatusList() {
  //this.loadingOverlay = true;
  await this.$http
    .get('ServiceItineraryStatus-list')
    .then((response) => {
      //this.loadingOverlay = false;
      this.statusSelectOptions = response.data.data;
    })
    .catch((e) => {
      //this.loadingOverlay = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    });
}

function computedStatusList() {
  if (this.statusSelectOptions.length > 0) {
    return this.statusSelectOptions.map((item, index) => {
      return {
        label: item.ValueDs,
        value: item.ProcessStatusId,
      };
    });
  }
}

function selectStatusItinerary(event) {
  this.ItineraryStatus = event.target.value;
}

async function submitUpdateItinerary() {
  try {
    this.$v.$touch();

    if (this.$v.$error) {
      this.loadingOverlay = false;
      this.verifyServiceId = true;
      this.verifyVesselModel = true;
      this.verifyShippingAgencyId = true;
      throw this.$t('label.errorsPleaseCheck');
    }

    if(this.ServicePortId === this.OrigitPortId){
      this.loadingOverlay = false;
      throw this.$t('label.notsameAsOrigin');
    }

    let portOrigin = this.itemsOriginPortList.find((item) => item.ServicePortId == this.OrigitPortId );
    let portDestiny = this.itemsPortList.find((item) => item.ServicePortId == this.ServicePortId );

    if(portOrigin.PortId !== this.branch.PortId && portDestiny.PortId !== this.branch.PortId){
      this.loadingOverlay = false;
      throw this.$t('label.onePortCompanyBranch');
    }

    let fecha1 = this.StartDate.split("/");
    fecha1 = fecha1[2]+fecha1[1]+fecha1[0];
    let fecha2 = this.EndDate.split("/");
    fecha2 = fecha2[2]+fecha2[1]+fecha2[0];

    if (fecha2 < fecha1) {
      this.loadingOverlay = false;
      this.EndDate = '';
      throw this.$t('label.errosDatePleaseCheck');
    } else if (fecha2==fecha1) {
      let hora2 = this.TimeSail.split(":");
      hora2 = hora2[0]+hora2[1];
      let hora1 = this.ArrivalTime.split(":");
      hora1 = hora1[0]+hora1[1];

      if (hora2 <= hora1) {
        this.loadingOverlay = false;
       // this.TimeSail = '';
        throw this.$t('label.errosHourPleaseCheck');
      }
    } 
    this.checkStatusItinerary();
  } catch (e) {
    this.loadingOverlay = false;
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: e,
      type: 'error',
    });
  }
}

async function checkStatusItinerary() {
  try {
    if (this.ItineraryStatus !== this.statusToVerify) {
      this.$swal
        .fire({
          /*text: `${this.$t('label.changeStatusQuestion')} ${this.statusServicesValue(
            this.ServiceStatus
            this.ServiceModel+" / "+val.Voyage
          )}?`,*/
          text: `${this.$t('label.changeStatusQuestion')+' '} ${this.ServiceModel+" / "+this.Voyage}?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#42AA91',
          cancelButtonColor: '#E1373F',
          confirmButtonText: this.$t('button.confirm'),
          cancelButtonText: this.$t('button.cancel')
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.updateItinerary();
          }
        });
    } else {
      this.updateItinerary();
    }
  } catch (e) {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: e,
      type: 'error',
    });
  }
}

async function updateItinerary() {
  this.loadingOverlay = true;
  const ServiceItineraryJson = {
    ItineraryId: this.ItineraryId,
    ServiceVesselId: this.ServiceVesselId,
    ServicePortOriginId: this.ServicePortId,
    ServicePortDestinationId: this.OrigitPortId,
    Eta: `${this.StartDate} ${this.ArrivalTime}`,
    Etd: `${this.EndDate} ${this.TimeSail}`,
    Voyage: this.Voyage,
    Color: this.Color,
    ShippingAgencyId: this.ShippingAgencyId.value,
    ItineraryStatus: this.ItineraryStatus,
    ItineraryObservation: this.ItineraryObservation,
  };

  await this.$http
    .put('ServiceItinerary-update', ServiceItineraryJson, {
      root: 'ServiceItineraryJson',
    })
    .then((response) => {
      if (response.status === (200 || 201)) {
        const messageSuccess = response.data.data[0].Response;
        this.$emit('refresh-data-calendar');
        this.loadingOverlay = false;
        this.resetData();
        this.closeModal(false);
        this.$notify({
          group: 'container',
          title: '¡Solicitud Exitosa!',
          text: messageSuccess,
          type: 'success',
        });
      }
    })
    .catch((e) => {
      this.loadingOverlay = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: 'error',
      });
    });
}

function statusServicesValue(statusServices) {
  const status = this.statusSelectOptions.filter(
    (element) => element.ProcessStatusId === statusServices
  );
  if (status.length > 0 && status[0].ValueDs === 'INACTIVO') {
    return 'desactivar';
  } else if (status.length > 0 && status[0].ValueDs === 'CANCELADO') {
    return 'cancelar';
  } else {
    return 'atracar';
  }
}

function isShippingValid() {
  if (this.ShippingAgencyId === ''  || this.ShippingAgencyId === null) {
    return false;
  } else {
    return true;
  }
 // return (this.ShippingAgencyId == null || this.ShippingAgencyId == "") ? '' : 'valido';
}

function fechaNoValida(date) {
  return date < this.fechaAnterior || date > this.fechaSiguiente;
}

async function recordList () {

  this.loadingTable = true;
  this.items = [];
  let i = 1;
  
  let listado = Array;
  await this.$http.get("ServiceItineraryHist-list", { ItineraryId: this.ItineraryId })
  .then(response => {
    listado = [...response.data.data];
    this.items = listado.map(listado => Object.assign({}, this.items, {
      Nro: i++,
      Arrival: listado.ArrivalDs+' '+DateFormater.formatDateTimeWithSlash(listado.Arrival)+' '+listado.VoyageArrival,
      Departure: listado.DepartureDs+' '+DateFormater.formatDateTimeWithSlash(listado.Departure)+' '+listado.VoyageDeparture,
      Usuario: listado.TransaLogin ? listado.TransaLogin:'N/A',
      Fecha: listado.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(listado.TransaRegDate) : 'N/A',
    }));
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  })
  .finally(() => {
    this.loadingTable = false;
  });
}

function evento(event) {
  this.EndDate = event;
}

export default {
  name: 'modalItinerario',
  components: {
    CustomTabs,
    CustomTab,
  },
  data,
  props: {
    title: String,
    modal: null,
    editModal: Boolean,
    itinerarySelected: Object,
    drop: Boolean,
  },
  mixins: [GeneralMixin, Servicio, ChoferVehiculo],
  directives: UpperCase,
  watch: {
    itinerarySelected: async function(val) {
      if (Object.keys(val).length !== 0) {
        await this.serviceById(val.ServiceId);
        await this.moutedServiceVesselList(this.ServiceId);
        this.ItineraryId = val.ItineraryId;
        this.ServiceVesselId = val.ServiceVesselId;
        this.OrigitPortId = val.ServicePortDestinationId;
        this.ServicePortId = val.ServicePortOriginId;
        this.StartDate = DateFormater.formatOnlyDateWithSlash(val.Eta);
        let arrHora = val.Eta.split(" ");
        let hora = arrHora[1].split(":");
        this.ArrivalTime = hora[0]+":"+hora[1];
        this.EndDate = DateFormater.formatOnlyDateWithSlash(val.Etd);
        arrHora = val.Etd.split(" ");
        hora = arrHora[1].split(":");
        this.TimeSail = hora[0]+":"+hora[1];
        this.Voyage = val.Voyage;
        this.Color = val.Color;
        this.ItineraryObservation = val.ItineraryObservation;
        this.Status = val.Status === ('ACTIVO' || 1) ? 1 : 0;
        this.VesselModel = val.VesselName;
        this.ServiceId = val.ServiceId;
        this.ItineraryStatus = val.ItineraryStatus;
        this.statusToVerify = val.ItineraryStatus;
        this.ShippingAgencyId = {
          label: val.ShippingAgencyName,
          value: val.ShippingAgencyId,
        },
        await this.recordList();
        await this.moutedServicePortList(this.ServiceId);
        this.tituloModal = this.$t('label.edit')+" "+this.$t('label.service')+": "+this.ServiceModel+" / "+val.Voyage;
        this.$v.$touch();
      }else {
      this.verifyShippingAgencyId = false;
      this.verifyServiceId = false;
      this.verifyVesselModel = false;
      }
    },
    modal: async function(val) {
      let valor = val;
      if (val==undefined) val = true;
      if (val!=false) {
        val = true;
      }

      this.showModal = val;
      this.resetData();
      this.tituloModal = this.title;

      if (val !== false) {
        this.verifyShippingAgencyId =  false;
        await this.mountedServiceList();
        await this.mounteStatusList();
        await this.getShipping('10fafb97-1575-4c71-8cdb-7f8f3c0c1245');
        if ((valor!=undefined) && (valor!="") && (valor!=true)) {
          this.ServiceModel = {
            label: valor.label,
            value: valor.value,
          }
          this.selectServices(this.ServiceModel);
        }
      }

    },
  },
  validations: Itinerario,
  methods: {
    closeModal,
    functionDate,
    functionDate2,
    mountedServiceList,
    moutedServicePortList,
    selectServices,
    moutedServiceVesselList,
    selectVessel,
    saveSNewItinerary,
    resetData,
    serviceById,
    mounteStatusList,
    statusServicesValue,
    submitUpdateItinerary,
    checkStatusItinerary,
    updateItinerary,
    selectServicePort,
    selectServiceOriginPort,
    fuseSearchShipping,
    getShipping,
    fechaNoValida,
    recordList,
    handleTab,
    evento,
    selectStatusItinerary
  },
  computed: {
    computedServiceList,
    computedInputSelect,
    computedServiceDestinationPortList,
    computedServiceOriginPortList,
    computedServiceVesselList,
    computedSelectVessel,
    ...mapState({
      branch: (state) => state.auth.branch,
      user: state => state.auth.user,
    }),
    computedMessagePortListEmpty,
    computedStatusList,
    isShippingValid,
    shippingOptions,
    fields
  },
};
</script>
<style lang="scss">
.text-area-descripcion-itinerario {
  textarea {
    resize: none !important;
  }
}
.select-services--port .vs__dropdown-menu {
  max-height: 280px !important;
}
.select-services--correct {
  .vs__dropdown-toggle {
    border-color: #2eb85c !important;
  }
}
.select-services--error {
  .vs__dropdown-toggle {
    border-color: #e55353 !important;
  }
}

.center-cell {
  text-align: center;
}
 

  .card-body .card-header {
    background-color: #5D6164 !important;
    color: #fff;
  }
</style>
